



















import Vue from 'vue';

export default Vue.extend({
  name: 'TheFabButton',
  props: {
    disabled: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    icon: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    mdiIcon: {
      type: String,
    },
    vicon: {
      type: String,
    },
  },
});
